html {
  background-color: #8CA0E3;
}

/* gopher */

body {
  background-color: #8CA0E3 !important;
  font-family: 'Prompt', sans-serif !important;
  color: white !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900 !important;
  font-family: 'Agbalumo', sans-serif !important;
  color: #ffe974 !important;
}

.booknow {
  background-color: #ffe974;
}

.footer {
  /* background-color: #f28f6b; */
  color: white;
}

.black {
  color: black !important
}

#splash {
  /* opacity:0.8; */
  background-color:#ffe974;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
}